var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('projects.Projects'),"description":_vm.$t('projects.FromHereYouCanControlProjects')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('projects.SearchForAProject'),"emptyTableText":_vm.$t('projects.ThereAreNoProjects'),"buttonRole":_vm.$user.admin || _vm.$user.role.projects_add,"localData":true,"emptyTableSubText":_vm.$t('projects.TryAddingItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('projects.ProjectName'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'start_date',
            title: _vm.$t('projects.StartDate'),
            type: 'text',
            sort: true,
            icon: 'calendar-alt'
          },
          {
            column: 'end_date',
            title: _vm.$t('projects.EndDate'),
            type: 'text',
            sort: true,
            icon: 'calendar-alt'
          },
          {
            column: 'balance',
            title: _vm.$t('projects.Balance'),
            type: 'text',
            sort: true,
            icon: 'usd-square'
          },
          {
            column: 'status',
            title: _vm.$t('projects.Status'),
            type: 'status',
            sort: true,
            hideMobile: true,
            values: [
              { title: _vm.$t('projects.ItIsOpen'), value: 0, color: 'dark' },
              {
                title: _vm.$t('projects.InProgress'),
                value: 1,
                color: 'primary',
              },
              { title: _vm.$t('projects.Very'), value: 2, color: 'success' },
              { title: _vm.$t('projects.canceled'), value: 3, color: 'danger' } ],
          },
          {
            column: 'options',
            title: _vm.$t('projects.Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role: _vm.$user.admin || _vm.$user.role.projects_edit,
              },
              {
                name: 'link',
                role: _vm.$user.admin || _vm.$user.role.employees_edit,
                title: 'إيداع المشروعات',
                icon: 'donate',
                link: '/projectsDeposits/create?project_id=',
              },
              {
                name: 'link',
                role: _vm.$user.admin || _vm.$user.role.employees_edit,
                title: 'سحب المشروعات',
                icon: 'money-bill',
                link: '/projectsWithdrawals/create?project_id=',
              },
               {
                name: 'link',
                role: _vm.$user.admin || _vm.$user.role.employees_edit,
                title: 'نقل المشروعات',
                icon: 'exchange',
                link: '/projectsTransfers/create?project_id=',
              },
              {
                name: 'delete',
                role: _vm.$user.admin || _vm.$user.role.projects_delete,
              } ],
          } ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: _vm.$t(
            'allerts.areYouReallySureToDeleteTheItem'
          ),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }