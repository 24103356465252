<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('projects.Projects')"
          :description="$t('projects.FromHereYouCanControlProjects')"
        />

        <indexTable
          :searchText="$t('projects.SearchForAProject')"
          :emptyTableText="$t('projects.ThereAreNoProjects')"
          :buttonRole="$user.admin || $user.role.projects_add"
          :localData="true"
          :emptyTableSubText="$t('projects.TryAddingItemsToTheTableAndTryAgain')"
          :cloumns="[
            {
              column: 'name',
              title: $t('projects.ProjectName'),
              type: 'mainLink',
              sort: true,
            },
            {
              column: 'start_date',
              title: $t('projects.StartDate'),
              type: 'text',
              sort: true,
              icon: 'calendar-alt'
            },
            {
              column: 'end_date',
              title: $t('projects.EndDate'),
              type: 'text',
              sort: true,
              icon: 'calendar-alt'
            },
            {
              column: 'balance',
              title: $t('projects.Balance'),
              type: 'text',
              sort: true,
              icon: 'usd-square'
            },
            {
              column: 'status',
              title: $t('projects.Status'),
              type: 'status',
              sort: true,
              hideMobile: true,
              values: [
                { title: $t('projects.ItIsOpen'), value: 0, color: 'dark' },
                {
                  title: $t('projects.InProgress'),
                  value: 1,
                  color: 'primary',
                },
                { title: $t('projects.Very'), value: 2, color: 'success' },
                { title: $t('projects.canceled'), value: 3, color: 'danger' },
              ],
            },
            {
              column: 'options',
              title: $t('projects.Settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: $user.admin || $user.role.projects_edit,
                },
                {
                  name: 'link',
                  role: $user.admin || $user.role.employees_edit,
                  title: 'إيداع المشروعات',
                  icon: 'donate',
                  link: '/projectsDeposits/create?project_id=',
                },
                {
                  name: 'link',
                  role: $user.admin || $user.role.employees_edit,
                  title: 'سحب المشروعات',
                  icon: 'money-bill',
                  link: '/projectsWithdrawals/create?project_id=',
                },
                 {
                  name: 'link',
                  role: $user.admin || $user.role.employees_edit,
                  title: 'نقل المشروعات',
                  icon: 'exchange',
                  link: '/projectsTransfers/create?project_id=',
                },
                {
                  name: 'delete',
                  role: $user.admin || $user.role.projects_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem: $t(
              'allerts.areYouReallySureToDeleteTheItem'
            ),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>

import indexHeader from "../elements/index/indexHeader.vue";
import indexTable from "../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/projects"
    };
  },
  components: {
    indexHeader,
    indexTable,
  },
};
</script>
